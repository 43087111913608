<template>
  <div class="accessHistoryDetails rounded-sm bg-white p-4 mb-4">
    <template>
      <div class="d-flex align-items-center accessHistoryDetails__studentInfo">
        <MemberProfilePicture
          :name="member.fullname"
          :coverImageUrl="member.cover_image_url"
          :customClass="'mr-4 member-image tlw-text-2xl'"
        />
        <div>
          <h1
            pill
            :class="enrollment.status === 'active' ? 'badge-success' : 'badge-danger'"
            class="badge mb-2 badge-pill"
          >
            {{ $t(`sparkmembers.members-details.enrollment-details.details.enrollment.${enrollment.status}`) }}
          </h1>
          <h4 class="font-weight-bold student-name">{{ member.fullname }}</h4>
          <p class="grey-text my-1 font-size-sm">{{ member.email }}</p>
          <div v-if="member.phone">
            <span class="grey-text font-size-sm">{{ member.phone }}</span>
            <hs-icon variant="brand" icon="whatsapp" size="15" class="ml-1 grey-text" />
          </div>
        </div>
      </div>
      <hr class="my-4" />
      <div class="mb-4 accessHistoryDetails__enrollmentInfo">
        <p class="font-weight-bold font-size-sm">
          {{ $t('sparkmembers.members-details.enrollment-details.access-history.components.details.product-name') }}
        </p>
        <h5 class="font-weight-bold product-title mt-1">{{ enrollment.school_product.title }}</h5>
        <div class="my-2 d-flex align-items-center">
          <hs-progress
            height="8px"
            :value="enrollment.progress"
            :max="100"
            variant="purple"
            class="col-8 col-md-10 mr-4 p-0"
          />
          <span class="progress-text font-size-sm font-weight-bold">{{
            `${enrollment.progress.toFixed(1)}% de progresso`
          }}</span>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row md:tlw-gap-12">
        <div class="access-container mb-3 mb-md-0">
          <p class="font-size-sm font-weight-bold mb-0">
            {{ $t('sparkmembers.members-details.enrollment-details.access-history.components.details.first-access') }}
          </p>
          <span class="font-size-sm">{{ enrollment.firstAccessDate }}</span>
        </div>
        <div class="access-container mb-3 mb-md-0">
          <p class="font-size-sm font-weight-bold mb-0">
            {{ $t('sparkmembers.members-details.enrollment-details.access-history.components.details.last-access') }}
          </p>
          <span class="font-size-sm">{{ enrollment.lastAccessDate }}</span>
        </div>
        <div class="access-container mb-3 mb-md-0">
          <p class="font-size-sm font-weight-bold mb-0">
            {{
              $t('sparkmembers.members-details.enrollment-details.access-history.components.details.completed-content')
            }}
          </p>
          <span class="font-size-sm">{{ completedContents }}</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'Details',
  components: {
    MemberProfilePicture: () => import('@/components/MemberProfilePicture.vue'),
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    enrollment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    completedContents() {
      return `${this.enrollment.lessons_info.completed.length} de ${this.enrollment.lessons_info.total}`;
    },
  },
};
</script>
<style lang="scss">
.accessHistoryDetails {
  .member-image {
    width: 80px;
    height: 80px;
  }
  .badge {
    font-size: 12px;
    padding: 4px 12px;
  }
  .badge-success {
    background-color: #348535 !important;
  }
  .badge-danger {
    background-color: #db3939 !important;
  }
  .grey-text {
    color: #525252;
  }
  .progress-text {
    color: #6f6f6f;
  }
  @media (min-width: 1280px) {
    .access-container {
      min-width: 157px;
    }
  }
  &__studentInfo {
    .student-name {
      font-size: 20px;
    }
  }
  &__enrollmentInfo {
    .product-title {
      font-size: 16px;
    }
  }
}
</style>
